import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import {db} from "components/utils/firebase";
import suuportImge from "Assets/supportImage.png";
import First from 'components/first';
import Third from 'components/third';
import Second from 'components/second';
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import Fourth from 'components/Fourth';
import { Button, Input, Modal, Spin } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface TicketInterface {
  Title: string,
  Id: string,
  Description: string,
  Status: "active" | "inprogress" | "solved",
  CreatedOn: string,
  Plant_id: string,
  ResponseAdmin: string,
  CreatedBy: {
    Name: string,
    Email: string,
    PhoneNumber: number | null,
  }
}

interface FormInterface {
  Name: string,
  Email: string,
  PhoneNumber: number | null,
}

interface InconvenienceProps {
  Title: string,
  Description: string
}

const App = () => {
  const date = new Date()
  const day = date.getDate();
  const month = date.getMonth()+1;
  const year = date.getFullYear();
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<{[key: string]: string | number} | undefined>(undefined);
  const [searchTicket, setSearchTicket] = useState<string>("");
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [curStep, setCurStep] = useState<number>(1);
  const [plants, setPlants] = useState<{[key: string]: any}[]>([]);
  const [toShow, setToShow] = useState<boolean>(false);
  const navigation = useNavigate();
  const email = useRef<any>(null);
  const password = useRef<any>(null);
  const [ticketData, setTicketData] = useState<TicketInterface>({
    Title: "",
    Id: Math.random().toString(36).slice(2),
    Description: "",
    Status: "active",
    CreatedOn: `${day}-${month}-${year}`,
    Plant_id: "--",
    ResponseAdmin: "",
    CreatedBy: {
      Name: "",
      Email: "",
      PhoneNumber: null,
    }
  }); 

  const plantsCollections = collection(db, "Plants");
  const ticketsCollections = collection(db, "Tickets");
  const auth = getAuth();

  useEffect(() => {
    const getPlants = async () => {
      setLoading(true);
      const docs = await getDocs(plantsCollections);
      setPlants(docs.docs.map((doc) => doc.data()));
    }

    getPlants();
    setLoading(false);
  }, []);

  const handlePlantSet = (selectedPlant: string) => {
    if(selectedPlant){
      const tticketData = JSON.parse(JSON.stringify(ticketData));
      tticketData.Plant_id = selectedPlant;
      setTicketData(tticketData);
    }
  }

  const handleClientDataFeed = (clientData:FormInterface) => {
    const tticketData = JSON.parse(JSON.stringify(ticketData));
    tticketData.CreatedBy = clientData;
    setTicketData(tticketData);
  }

  const handleInconvienceFeed = async (inconvenience: InconvenienceProps) => {
    const tticketData = JSON.parse(JSON.stringify(ticketData));
    tticketData.Title = inconvenience.Title;
    tticketData.Description = inconvenience.Description;
    setTicketData(() => {
      addDoc(ticketsCollections, tticketData);
      return tticketData;
    });
  }


  const hanfleFirst = () => setCurStep(2);
  const hanfleSecond = (where: number) => setCurStep(where);
  const handleThird = (where: number) => setCurStep(where);

  const getComponentToShow = () => {
    switch(curStep) {
      case 1:
        return <First plantId={ticketData.Plant_id} plantsList={plants} setPlantFn={handlePlantSet} hanfleFirst={hanfleFirst} />;
      case 2:
        return <Second handleClientDataFeed={handleClientDataFeed} hanfleSecond={hanfleSecond} initialData={ticketData.CreatedBy} />;
      case 3:
        return <Third handleInconvienceFeed={handleInconvienceFeed} handleThird={handleThird} title={ticketData.Title} description={ticketData.Description} />;
      case 4:
        return <Fourth id={ticketData.Id}/>
    }
  }

  const handleSearchTicket = async () => {
    if(searchTicket.length > 0){
      setLoading(true);
      const data = await query(ticketsCollections, where("Id", "==", searchTicket));
      const querySnapshot = await getDocs(data);
      if( querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
          setIsModalVisible(doc.data());
        });
      } else{
        toast("No ticket found with this Id", {type:"error", theme:"colored"})
      }
      setLoading(false);
    } else{
      toast("Please enter valid ticket Id", {type:"error", theme:"colored"})
    }
  }

  const handleLogin = () => {
    const emailL: string = email.current.input.value;
    const passwordL: string = password.current.input.value;

    if(emailL !== null && emailL.length > 0 && passwordL !== null && passwordL.length > 0){
      setLoading(true);
      signInWithEmailAndPassword(auth, emailL, passwordL)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        navigation("/admin");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast(`${errorMessage}`);
      });

      setLoading(false);
    }
  }
  

  return (
    <>
      <ToastContainer
					position='top-right'
					autoClose={3000}
					draggable={false}
					closeButton={false}
					hideProgressBar
					className='react-toastify-component'
				/>
      <Modal className={`modal-container ${isModalVisible?.Status}`} title={`Ticket Id: ${isModalVisible?.Id}`} visible={isModalVisible !== undefined} 
      onOk={() => setIsModalVisible(undefined)} onCancel={() => setIsModalVisible(undefined)}>
        <div className="modal-container-main-container">
          <div className="modal-container-title-container"> Inconvenience: <span>{isModalVisible?.Title}</span></div>
          <div className="modal-container-date-container">Created On: <span>{isModalVisible?.CreatedOn}</span></div>
          <div className="modal-container-description-container">Description: <span>{isModalVisible?.Description}</span></div>
          <div className="modal-container-admin-response-container">Response By Admin: <span>{isModalVisible?.ResponseAdmin === "" ? "Not yet responded" : isModalVisible?.ResponseAdmin}</span></div>
          <div className="modal-container-status-container">Status: <span>{isModalVisible?.Status}</span></div>
        </div>
      </Modal>
      <Modal className={`modal-container`} onCancel={() => setShowLogin(false)} title={`Login`} visible={showLogin} footer={null}>
        <Spin spinning={loading}>
          <div className="modal-container-login-main-container">
            <Input className="modal-container-login-item email" placeholder="Email" ref={email}/>
            <Input className="modal-container-login-item password" placeholder="Password" type="password" ref={password}/>
            <Button className="modal-container-login-item button" type="ghost" onClick={handleLogin}>Login</Button>
          </div>
        </Spin>
      </Modal>
      <Spin spinning={loading}>
      <div className='app-base-page-main-container'>
        <div className="app-base-page-left-container">
          <img className='app-base-page-left-vector' src={suuportImge} alt="support Vector" />
        </div>
        <div className="app-base-page-right-container">
          <div className="app-base-page-right-top"><span onClick={() => setShowLogin(true)}>Admin Login</span></div>
          <div className="app-base-page-right-middle">
            {!toShow ? (
              <div className="app-base-page-right-middle-first">
                <div className="app-base-page-right-prev-ticket-container">
                  <div className="app-base-page-right-prev-ticket-input-container">
                    <Input onChange={(e) =>setSearchTicket(e.target.value)} placeholder="Please enter you unique ticket number"></Input>
                  </div>
                  <Button onClick={handleSearchTicket} type="primary" className="app-base-page-right-prev-ticket-search-button-container">Search</Button>
                </div>
                <div className="or-container">OR</div>
                <div className="app-base-page-right-new-ticket-container">
                  <div className="app-base-page-right-new-ticket-create-button-container">
                    <Button onClick={() => setToShow(true)} type="primary" className="app-base-page-right-new-ticket-create-button">Create new ticket</Button>
                  </div>
                </div>
              </div>)
            :(
              <div className="app-base-page-right-middle-second">
                <div className="app-base-page-right-middle-stepbar">
                  <div className={`app-base-page-right-middle-stepbar-item first circle
                      ${curStep > 1 ? "doneC": "curC"}`}>1</div>
                  <div className={`app-base-page-right-middle-stepbar-item second bar 
                    ${curStep === 1 ? "upcB": curStep === 2 ? "curB" : "doneB"}`}></div>
                  <div className={`app-base-page-right-middle-stepbar-item second circle
                      ${curStep > 2 ? "doneC": curStep === 2 ? "curC" : "upcC"}`}>2</div>
                  <div className={`app-base-page-right-middle-stepbar-item third bar
                      ${curStep > 3 ? "doneB" : curStep === 3 ? "curB": "upcB"}`}></div>
                  <div className={`app-base-page-right-middle-stepbar-item third circle 
                      ${curStep > 3 ? "doneC" : curStep === 3 ? "curC": "upcC"}`}>3</div>
                </div> 
                <div className="app-base-page-right-middle-steps">
                  {getComponentToShow()}
                </div>
              </div>
            )}
          </div>
          <div className="app-base-page-right-bottom"></div>
        </div>
      </div>
      </ Spin>
    </>
  )
}

export default App;
