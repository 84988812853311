import React, { useEffect, useState } from "react";
import "./second.component.scss";
import { Button, Input } from 'antd';

interface FormInterface {
    Name: string,
    Email: string,
    PhoneNumber: number | any,
}

interface SecondProps {
    handleClientDataFeed: (clientData: FormInterface) => void;
    hanfleSecond: (where: number) => void;
    initialData: FormInterface;
}

const Second: React.FC<SecondProps> = ({handleClientDataFeed, hanfleSecond, initialData}) => {
    const [formData, setFormData] = useState<FormInterface>({
        Name: "",
        Email: "",
        PhoneNumber: null 
    });
    const [error, setError] = useState<string| undefined> (undefined);

    useEffect(() => {
        setFormData(initialData);
    },[initialData]);

    const updateFormData = (updatedData: string, field: "Name" | "Email" | "PhoneNumber", e: React.SyntheticEvent)  => {
        e.stopPropagation();
        e.preventDefault();

        const tFormData = JSON.parse(JSON.stringify(formData));
        tFormData[`${field}`] = updatedData;
        setFormData(tFormData);
    }


    const validateEmail = (email: string) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if(formData.Name === ""){
            setError("Name is blank");
        } else if(
        formData.Email === "") {
            setError("Email is blank");
        } else if(validateEmail(formData.Email) === null){
            setError("Email is not valid");
        } else if (formData.PhoneNumber === null){
            setError("Phone Number is not valid");
        } else {
            handleClientDataFeed(formData);
            setError(undefined);
            hanfleSecond(3);
        }
    }

    return (
        <div className="app-base-page-middle-steps-second-main-container">
            <div className="app-base-page-middle-steps-second-select-branch-text">
                Enter your details
            </div>
            {error ? <div className="error-field">{error}</div>: ""}
            <div className="app-base-page-middle-steps-second-form-main-container">
                <div className="app-base-page-middle-steps-second-form-container name">
                    <Input value={formData.Name} onChange={(e) => updateFormData(e.target.value, "Name", e)} placeholder="Name"/>
                </div>
                <div className="app-base-page-middle-steps-second-form-container email">
                    <Input value={formData.Email} onChange={(e) => updateFormData(e.target.value, "Email", e)} placeholder="Email"/>
                </div>
                <div className="app-base-page-middle-steps-second-form-container phoneNumber">
                    <Input value={formData.PhoneNumber} onChange={(e) => updateFormData(e.target.value, "PhoneNumber", e)} placeholder="Phone Number"/>
                </div>
            </div>
            <div className="app-base-page-middle-steps-second-button submit">
                    <Button onClick={handleFormSubmit} type="primary">Submit</Button>
            </div>
            <div className="app-base-page-middle-steps-second-button-container">
                <div className="app-base-page-middle-steps-second-button">
                    <Button onClick={() => hanfleSecond(1)} type="primary">Prev</Button>
                </div>
                <div className="app-base-page-middle-steps-second-button">
                    <Button onClick={() => hanfleSecond(3)} type="primary">Next</Button>
                </div>
            </div>
        </div>
    )
}

export default Second;