
import 'antd/dist/antd.css';
import React, { useState } from "react";
import "./first.component.scss";
import { Button, Select } from "antd";

interface FirstProps {
    plantsList: {[key: string]: any}[];
    setPlantFn: (plant: string) => void;
    hanfleFirst: () => void;
    plantId: string;
}

const First: React.FC<FirstProps> = ({plantId, plantsList, setPlantFn, hanfleFirst}) => {
    const { Option } = Select;
    const [currentPlant, setCurrentPlant] = useState<string>(plantId);
    const [error, setError] = useState<string| undefined> (undefined);

    const  handleChange = (e: string) =>{
        setCurrentPlant(e);
        setPlantFn(e);
    }
    return (
        <div className="app-base-page-middle-steps-first-main-container">
            <div className="app-base-page-middle-steps-first-select-branch-text">
                Select your branch
            </div>
            {error ? <div className="error-field">{error}</div>: ""}
            <div className="app-base-page-middle-steps-first-select-dropdown-container">
                <Select defaultValue={currentPlant} onChange={handleChange}>
                    <Option key={"--"} value="--">--</Option>
                    {
                        plantsList.map((plant) => <Option key={plant.Id} value={plant.Id}>{plant.Name}</Option>)
                    }
                </Select>
            </div>
            <div className="app-base-page-middle-steps-first-next-button">
                <Button onClick={currentPlant === "--" ? () => setError("Please select correct plant") : () =>  {setError(undefined); hanfleFirst()}} type="primary">Next</Button>
            </div>
        </div>
    );
}

export default First;