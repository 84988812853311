// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAvslvpPtkC7mtIZtIfOcK8yQ0kYhof1YM",
  authDomain: "support-system-1b1ef.firebaseapp.com",
  projectId: "support-system-1b1ef",
  storageBucket: "support-system-1b1ef.appspot.com",
  messagingSenderId: "461914177198",
  appId: "1:461914177198:web:1e5ab503aa23276087b73f",
  measurementId: "G-7KQN12SND6"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);