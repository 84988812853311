import { Button } from "antd";
import React from "react";
import "./Fourth.components.scss";

interface FourthInterface {
    id: string;
}

const Fourth: React.FC<FourthInterface> = ({id}) => {
    return (
        <div className="app-base-page-middle-steps-fourth-main-container">
            <div className="app-base-page-middle-steps-fourth-ticket-container">
                <div className="app-base-page-middle-steps-fourth-ticket-id-container">Ticket Created!</div>
                <div className="app-base-page-middle-steps-fourth-ticket-id-description">Please note down this id for future reference:<br /> Id: <span>{id}</span></div>
            </div>
            <div className="app-base-page-middle-steps-first-next-button submit">
                <Button onClick={() =>window.location.reload()} type="primary">Done</Button>
            </div>
        </div>
    )
}

export default Fourth;