import Admin from "components/Admin";
import App from "components/App";
import { Routes, Route } from "react-router-dom";
import React from "react";

const RoutesE: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/admin" element={<Admin />} />
        </Routes>
    )
}

export default RoutesE;