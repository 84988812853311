import React, { useEffect, useRef, useState } from "react";
import "./Admin.components.scss";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Button, DatePicker, Input, Modal, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";
import { db } from "components/utils/firebase";
import { toast } from "react-toastify";
import { Calendar, GitBranch, User } from "react-feather";
import logo from "Assets/ausamlogo2.png"

const Admin: React.FC = () => {
    const auth = getAuth();
    const [giveAccess, setGiveAccess] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const navigation = useNavigate();
    const [plants, setPlants] = useState<{[key: string]: any}[]>([]);
    const [ticketModal, setTicketModal] = useState<{[key: string]: string | number | any}>({});

    const [ tickets, setTickets ] = useState<any>([]);

    const plantsCollections = collection(db, "Plants");
    const ticketsCollections = collection(db, "Tickets");
    const [updated, setupdated] = useState<string>("");
    const adminResponse = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { TextArea } = Input;
    const {Option} = Select;
    const [statusS, setStatusS] = useState<string>("");
    const [filterH, setfilterH] = useState<any>([]);
    const [statusFilter, setStatusFilter] = useState<string>("All");
    const [date, setDate] = useState<string>("");

    useEffect(() => {
        const getPlants = async () => {
        //   setLoading(true);
          const docs = await getDocs(plantsCollections);
          const pdata = docs.docs.map((doc) => doc.data());
          const pudata:any = {}
          pdata.forEach((item) => pudata[`${item.Id}`] = item.Name);
          setPlants(pudata);
        }
    
        getPlants();
        // setLoading(false);
      }, []);

    const refetchData = () => {
        // const uid = user.uid;
        setGiveAccess(true);

        const getTickets = async () => {
        const data = await query(ticketsCollections);
        const querySnapshot = await getDocs(data);
        if( querySnapshot.size > 0) {
            const tTicketArr: any[] = [];
            querySnapshot.forEach((doc) => {
                tTicketArr.push(doc.data());
            });
            setTickets(tTicketArr);
        } else{
            toast("No tickets found", {type:"error", theme:"colored"})
        }
        };

        getTickets();
    };


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              setGiveAccess(true);

              const getTickets = async () => {
                const data = await query(ticketsCollections);
                const querySnapshot = await getDocs(data);
                if( querySnapshot.size > 0) {
                    const tTicketArr: any[] = [];
                    querySnapshot.forEach((doc) => {
                        tTicketArr.push(doc.data());
                    });
                    setTickets(tTicketArr);
                } else{
                    toast("No tickets found", {type:"error", theme:"colored"})
                }
              };

              getTickets();
            } else {
              // User is signed out
                setShowLogin(true);
            }
          });
    },[])

    useEffect(() => {
        let tTickets = [...tickets];
        if(statusFilter !== "All"){
            tTickets = tTickets.filter(item => item.Status === statusFilter);
        }

        if(date !== ""){
            tTickets = tTickets.filter(item => item.CreatedOn >= date);
        }

        setfilterH(tTickets);
    },[tickets]);

    useEffect(() =>{
        let tTickets = [...tickets];
        if(statusFilter !== "All"){
            tTickets = tTickets.filter(item => item.Status === statusFilter);
        }

        if(date !== ""){
            tTickets = tTickets.filter(item => item.CreatedOn >= date);
        }

        setfilterH(tTickets);
    },[statusFilter, date]);

    const handleUpdateResponse = async () => {
        if(updated.length > 0 || statusS.length > 0){
            setLoading(true);
            const data = await query(ticketsCollections, where("Id", "==", ticketModal.Id));
            const querySnapshot = await getDocs(data);
            if( querySnapshot.size > 0) {
                let tTicketArr = "";
                querySnapshot.forEach((doc) => {
                    tTicketArr = doc.id;
                });
                const docRef = doc(ticketsCollections, tTicketArr);
                if(statusS !== ""){
                    await updateDoc(docRef, {
                        Status: statusS
                    });
                 } 
                 if(updated.length > 0){
                    await updateDoc(docRef, {
                        ResponseAdmin: updated,
                    });
                 }
                 refetchData();
            } else{
                toast("No tickets found", {type:"error", theme:"colored"})
            }
            setLoading(false);
        }
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigation("/");
          }).catch((error) => {
            toast("Something went wrong",{type:"error", theme:"colored"})
        });
    }

    return (
        <>  
            <Modal className={`modal-container`} onCancel={() => navigation("/")}
             title={`Access not allowed`} visible={showLogin} onOk={() => navigation("/")}>
                <div className="modal-container-login-main-container">
                    You need to login to view this page.
                </div>
            </Modal>
            {Object.keys(ticketModal).length > 0 ?(
            <Modal className={`modal-container`}  maskClosable={false}
             title={`Ticket Id: ${ticketModal.Id}`} visible={Object.keys(ticketModal).length > 0} 
             onCancel={() => {setStatusS("");setupdated("");setTicketModal({})}}
             footer={[<Button onClick={() => {setStatusS("");setupdated("");setTicketModal({})}} 
             key={ticketModal.Id}>Cancel</Button>, 
             <Button loading={loading} className="update" onClick={handleUpdateResponse}  key={ticketModal.Id}>Update</Button>]}>
                 <Spin style={{height:"100vh"}} spinning={loading} >
                    <div className="modal-container-ticket-detail-main-container">
                        <div className="modal-container-ticket-detail-item title">Inconvenience: <span>{ticketModal.Title}</span></div>
                        <div className="modal-container-ticket-detail-item plant">Plant: <span>{plants[ticketModal.Plant_id]}</span></div>
                        <div className="modal-container-ticket-detail-item createdOn">Created On: <span>{ticketModal.CreatedOn}</span></div>
                        <div className="modal-container-ticket-detail-item createdBy">Created By: <span>{ticketModal.CreatedBy.Name}</span></div>
                        <div className="modal-container-ticket-detail-item createdByPh">Contact Number: <span>{ticketModal.CreatedBy.PhoneNumber}</span></div>
                        <div className="modal-container-ticket-detail-item createdByEm">Contact Email: <span>{ticketModal.CreatedBy.Email}</span></div> 
                        <div className="modal-container-ticket-detail-item status">Status: 
                            <span>
                                <Select defaultValue={statusS} style={{width:130}} onChange={(e) => setStatusS(e)}>
                                    <Option value="active">Active</Option>
                                    <Option value="In-Process">In Process</Option>
                                    <Option value="solved">Solved</Option>
                                </Select>
                            </span>
                        </div>
                        <div className="modal-container-ticket-detail-item description">Description:</div>
                        <div className="modal-container-ticket-detail-item description-input"><TextArea value={ticketModal.Description}/></div>
                        <div className="modal-container-ticket-detail-item ResponseAdmin">Admin Response:</div>
                        <div className="modal-container-ticket-detail-item ResponseAdmin-input"><TextArea ref={adminResponse} value={updated.length > 0 ? updated : ticketModal.ResponseAdmin} onChange={(e) => setupdated(e.target.value)}/></div>
                    </div>
                </Spin>
            </Modal>
            ):""}
            <Spin style={{height:"100vh"}} spinning={!giveAccess} >
            
            {
                giveAccess ? (
                    <div className="support-admin-main-container">
                        <div className="support-admin-navbar">
                            <div className="support-admin-navbar-logo-container">
                                <img src={logo} alt="logo" />
                            </div>
                            <div className="support-admin-navbar-logout-container" onClick={handleLogout}>Logout</div>
                        </div>
                        <div className="support-admin-body">
                            <div className="support-admin-body-filter-container">
                                <div className="support-admin-body-filter-container-filter-head">Filters:</div>
                                <div className="support-admin-body-filter-container-filter-items">
                                    <Select defaultValue={statusFilter} style={{width:130}} onChange={(e) => setStatusFilter(e)}>
                                        <Option value="All">All</Option>
                                        <Option value="active">Active</Option>
                                        <Option value="In-Process">In Process</Option>
                                        <Option value="solved">Solved</Option>
                                    </Select>
                                    <DatePicker onChange={(e) => setDate(`${e?.date()}-${(e?.month() || 0)+1}-${e?.year()}`)}  className="filter-datepicker"/>
                                </div>
                            </div>
                            <div className="support-admin-body-tickets-head-name">Tickets:</div>
                            <div className="support-admin-body-tickets-container">
                                {
                                    filterH.map((ticket: any, i: number) => {
                                        return (
                                        <div key={i} className="support-admin-body-ticket-container" onClick={() => {setStatusS(ticket.Status) ;setTicketModal(ticket)}}>
                                            <div className={`support-admin-body-ticket status ${ticket.Status}`}>{ticket.Status}</div>
                                            <div className="support-admin-body-ticket title">{ticket.Title}</div>
                                            <div className="support-admin-body-ticket created-on"><span><Calendar /></span>{ticket.CreatedOn}</div>
                                            <div className="support-admin-body-ticket plant"><span><GitBranch /></span>{plants[ticket.Plant_id]}</div>
                                            <div className="support-admin-body-ticket created by"><span><User /></span>{ticket.CreatedBy.Name}</div>
                                        </div>);
                                    })
                                }
                            </div>
                            
                        </div>
                    </div>
                ) : ""
            }
            </Spin>
        </>
    )
}

export default Admin;