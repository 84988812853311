import { Select, Input, Button } from "antd";
import React, { useState } from "react";
import "./third.component.scss";

interface InconvenienceProps {
    Title: string,
    Description: string
}

interface ThirdProps {
    handleThird: (where: number) => void;
    handleInconvienceFeed: (inconvenience: InconvenienceProps) => void;
    title: string;
    description: string;
}

const Third: React.FC<ThirdProps> = ({handleThird, handleInconvienceFeed, title, description}) => {
    const {Option}  = Select;
    const { TextArea } = Input;
    const [inconvenience,setInconvenience] = useState<InconvenienceProps>({Title:title, Description: description});

    const handleChange = (e: string, field: string) => {
        const tinconveneince = JSON.parse(JSON.stringify(inconvenience));
        tinconveneince[`${field}`] = e;
        setInconvenience(tinconveneince);
    }

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleInconvienceFeed(inconvenience);
        handleThird(4);
    }

    return (
        <div className="app-base-page-middle-steps-third-main-container">
            <div className="app-base-page-middle-steps-third-select-branch-text">
                Type the inconvenience
            </div>
            <div className="app-base-page-middle-steps-third-form-main-container">
                <div className="app-base-page-middle-steps-third-form-dropdown-container">
                    <Select placeholder="Select type of inconvenience" defaultValue={inconvenience.Title} style={{ width: 350 }} onChange={(e) => handleChange(e, "Title")}>
                        <Option value="--">--</Option>
                        <Option value="App has stopped working">App has stopped working</Option>
                        <Option value="Not able to login">Not able to login</Option>
                        <Option value="App not functioning correctly">App not functioning correctly</Option>
                        <Option value="Others">Others</Option>
                    </Select>
                </div>
                <div className="app-base-page-middle-steps-third-form-description-container">
                    <TextArea value={inconvenience.Description} onChange={(e) => handleChange(e.target.value, "Description")} showCount maxLength={500} style={{ width: 350, height: 150 }} placeholder="Please decribe you inconvenience" ></TextArea>
                </div>
            </div>
            <div className="app-base-page-middle-steps-third-button-container">
                <div className="app-base-page-middle-steps-third-button">
                    <Button onClick={() => handleThird(2)} type="primary">Prev</Button>
                </div>
                <div className="app-base-page-middle-steps-third-button submit">
                    <Button onClick={handleFormSubmit} type="primary">Submit</Button>
                </div>
            </div>
        </div>
    )
}

export default Third;